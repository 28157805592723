
import Vue from 'vue';
import { getFlashcardInstallationByHost, getTokenRouteLink, InstallationInfo } from '@/service/loginService';

export default Vue.extend({
	props: {
		target: {
			type: URL,
			required: true,
		},
		accessToken: {
			type: String,
			required: true,
		},
	},

	async created() {
		try {
			const flashcardInstallationId = (await this.getFlashcardInstallationByHostWrapper()).id;
			window.location.href = getTokenRouteLink(
				flashcardInstallationId,
				this.accessToken,
				this.target.pathname + this.target.search,
				this.target.host
			);
		} catch (e: any) {
			if (e.response.status === 424) {
				this.$emit('missingHostWarning', e.response.data.message);
			} else if (e.response.status === 503) {
				this.$emit('podioFailedError', e.response.data.message);
			} else if (e.response.status === 403) {
				this.$emit('forbiddenError');
			} else {
				this.$emit('installationError', 'Unbekannter Fehler');
				console.error(e.response.data);
			}
		}
	},

	methods: {
		async getFlashcardInstallationByHostWrapper(): Promise<InstallationInfo> {
			return await getFlashcardInstallationByHost(this.target.host, this.accessToken);
		},
	},
});
