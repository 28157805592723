
import Vue from 'vue';
import { applicationUrl } from '../../config';
import { getFlashcardInstallationList, getTokenRouteLink, InstallationInfo } from '@/service/loginService';
import BaseWarning from '@/components/BaseWarning.vue';

export default Vue.extend({
	components: {
		BaseWarning,
	},

	data: (): { loading: boolean; alphabet: any; bookmarkletLoginLink: string } => {
		return {
			loading: true,
			alphabet: {},
			bookmarkletLoginLink: `javascript:(() => {window.location.href = \`${applicationUrl}/directLogin?target=$\{encodeURIComponent(window.location.hostname + window.location.hash.substring(2))}\`;})();`,
		};
	},

	props: {
		accessToken: {
			type: String,
			required: true,
		},
		warning: {
			type: String,
			required: false,
		},
	},

	created() {
		localStorage.clear();
		this.populateInstallations();
	},

	methods: {
		async populateInstallations() {
			try {
				let flashcardInstallationInformationList = await this.getFlashcardInstallationListWrapper();

				for (let flashcardInstallation of flashcardInstallationInformationList) {
					let tokenRouteLink = getTokenRouteLink(flashcardInstallation.id, this.accessToken);

					let firstLetterOfName = flashcardInstallation.name[0].toLocaleUpperCase();

					if (this.alphabet[firstLetterOfName]) {
						this.alphabet[firstLetterOfName].installations.push({
							projectName: flashcardInstallation.name,
							tokenRouteLink: tokenRouteLink,
						});
					} else {
						this.alphabet[firstLetterOfName] = { letter: firstLetterOfName, installations: [] };
						this.alphabet[firstLetterOfName].installations.push({
							projectName: flashcardInstallation.name,
							tokenRouteLink: tokenRouteLink,
						});
					}
				}
				this.$emit('loadingComplete');
				this.$forceUpdate();
				this.loading = false;
			} catch (e: any) {
				if (e.response.status === 424) {
					this.$emit('installationError', e.response.data.message);
				} else if (e.response.status === 503) {
					this.$emit('podioFailedError', e.response.data.message);
				} else if (e.response.status === 403) {
					this.$emit('forbiddenError');
				} else {
					this.$emit('installationError', 'Unbekannter Fehler');
					console.error(e.response.data);
				}
			}
		},

		async getFlashcardInstallationListWrapper(): Promise<InstallationInfo[]> {
			return await getFlashcardInstallationList(this.accessToken);
		},

		preventNavigation(event: any) {
			event.preventDefault();
		},
	},
});
